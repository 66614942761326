<template>
  <div>
    <Divider dashed style="color:#fff">调整供应商</Divider>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">选择供应商</h4>
      <div>
        <RadioGroup
          v-model="selectedCompanyId"
          vertical
        >
          <Radio
            v-for="item in companyArray"
            :key="'ps_'+item.id"
            :label="item.id"
          >
            <span>{{item.name}}</span>
          </Radio>
        </RadioGroup>
      </div>
    </div>
    <div class="text-right">
      <Button
        type="success"
        size="small"
        :disabled="selectedTaskItemIds.length===0"
        @click="handleSubmit"
      >确认配置</Button>
    </div>
  </div>
</template>

<script>
import { companyMixin } from '@/assets/mixins/company'
import { setLabor } from '@/api/msp/taskitem'
export default {
  mixins: [companyMixin],
  data () {
    return {
      type: 3, // 获取不同端类型的公司
      selectedCompanyId: null
    }
  },
  created () {
    this.getCompanyArray()
  },
  computed: {
    beginUpdate () {
      return this.$store.state.board.beginUpdate
    },
    selectedTaskItemIds () {
      return this.$store.state.board.selectedTaskItemIds
    }
  },
  methods: {
    handleSubmit () {
      if (!this.selectedCompanyId) {
        this.$Notice.warning({ desc: '请选择一个供应商' })
        return false
      }
      const postData = {
        companyId: this.selectedCompanyId,
        taskitemIds: JSON.stringify(this.selectedTaskItemIds)
      }
      setLabor(postData).then(res => {
        if (res && res.errcode === 0) {
          this.$store.commit('set_board_selectedTaskItemIds', [])
          this.$store.commit('set_board_beginUpdate', new Date())
          this.$Notice.success({ desc: '操作成功' })
        }
      })
    }
  }
}
</script>
